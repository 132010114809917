import Image from "next/image";
import classnames from "classnames";

interface Protocol {
  src: string;
  alt: string;
  title: string;
  inspiration: string;
  inspoImg: string;
  inspoAlt: string;
}
const protocols: Protocol[] = [
  {
    src: "/images/protocols/ice-bath.jpg",
    alt: "older woman enjoying ice bath in frozen lake",
    title: "Cold Exposure",
    inspiration: "Wim Hof",
    inspoAlt: "Wim Hof Portrait",
    inspoImg: "/images/experts/wim-hof.png",
  },
  {
    src: "/images/protocols/morning-walks.jpg",
    alt: "young woman taking morning walk to get morning light from the sun and improve her health",
    title: "Morning Walks",
    inspiration: "Andrew Huberman",
    inspoAlt: "Andrew Huberman Portrait",
    inspoImg: "/images/experts/andrew-huberman.png",
  },
  {
    src: "/images/protocols/vo2max.jpg",
    alt: "man at home riding stationary bike doing zone2 cardio to improve his vo2 max",
    title: "VO2 Max Training",
    inspiration: "Peter Attia",
    inspoAlt: "Peter Attia Portrait",
    inspoImg: "/images/experts/peter-attia.png",
  },
  {
    src: "/images/protocols/journaling.jpg",
    alt: "beautiful journal with pen for writing words of mindful reflection to improve mental health",
    title: "Journaling",
    inspiration: "Tim Ferris",
    inspoAlt: "Tim Ferris Portrait",
    inspoImg: "/images/experts/tim-ferris.png",
  },
  {
    src: "/images/protocols/resistance-training.jpg",
    alt: "young man doing resistance training with weights to improve his strength and muscle mass",
    title: "Resistance Training",
    inspiration: "Mike Israetel",
    inspoAlt: "Mike Israetel Portrait",
    inspoImg: "/images/experts/mike-israetel.png",
  },
  {
    src: "/images/protocols/uninterrupted-sleep.jpg",
    alt: "beautiful clean bed with skylight in modern loft for getting uninterrupted sleep",
    title: "Uninterrupted Sleep",
    inspiration: "Bryan Johnson",
    inspoAlt: "Bryan Johnson Portrait",
    inspoImg: "/images/experts/bryan-johnson.png",
  },
  {
    src: "/images/protocols/mindful-meditation.jpg",
    alt: "people seated on yoga mats with hands on knees in mindful meditation",
    title: "Mindful Meditation",
    inspiration: "Andy Puddicombe",
    inspoAlt: "Andy Puddicombe Portrait",
    inspoImg: "/images/experts/andy-puddicombe.png",
  },
  {
    src: "/images/protocols/5x5.jpg",
    alt: "woman setting up barbell for deadlifts as part of 5x5 strength training protocol",
    title: "5x5 Strength Training",
    inspiration: "Mark Rippetoe",
    inspoAlt: "Mark Rippetoe Portrait",
    inspoImg: "/images/experts/mark-rippetoe.png",
  },
  {
    src: "/images/protocols/restorative-yoga.jpg",
    alt: "two women doing restorative yoga to improve their flexibility and mobility",
    title: "Restorative Yoga",
    inspiration: "Tara Stiles",
    inspoAlt: "Tara Stiles Portrait",
    inspoImg: "/images/experts/tara-stiles.png",
  },
  {
    src: "/images/protocols/posture-improvement.jpg",
    alt: "woman helping other woman improve her posture in yoga stretch",
    title: "Posture Improvement",
    inspiration: "Esther Gokhale",
    inspoAlt: "Esther Gokhale Portrait",
    inspoImg: "/images/experts/esther-gokhale.png",
  },
];

const NoNonsense = () => {
  return (
    <section className="relative w-screen items-center text-center">
      <div className="mb-12 flex w-full flex-col items-center gap-3 px-12">
        <h2
          className={classnames(
            "font-bold text-base-content/80",
            "text-2xl md:text-3xl lg:text-4xl",
          )}
        >
          Escape the noise.{" "}
          <span className="whitespace-nowrap font-bold italic">
            Build your temple.
          </span>
        </h2>
        <p
          className={classnames(
            "text-normal max-w-2xl text-base-content/60",
            "text-sm md:text-base lg:text-lg",
          )}
        >
          Don’t let social media myths dictate your health journey. Stack
          simple, effective, protocols inspired by top experts.
        </p>
      </div>

      <div
        // eslint-disable-next-line react/no-unknown-property
        x-data="{}"
        // eslint-disable-next-line react/no-unknown-property
        x-init="$nextTick(() => {
        let ul = $refs.logos;
        ul.insertAdjacentHTML('afterend', ul.outerHTML);
        ul.nextSibling.setAttribute('aria-hidden', 'true');
    })"
        className="group relative inline-flex w-full flex-nowrap overflow-hidden"
      >
        <div className="group-hover:pause absolute left-0 z-10 h-full w-full bg-gradient-to-r from-base-100/50 via-transparent to-base-100/50 opacity-100 sm:hidden" />
        <div className="group-hover:pause absolute left-0 z-10 hidden h-full w-full bg-gradient-to-r from-base-100 via-transparent to-base-100 opacity-100 sm:block" />
        <ul className="group-hover:pause animate-infinite-scroll flex items-start justify-start [&_img]:max-w-none [&_li]:mx-8">
          {protocols.map((protocol, index) => (
            <li key={index}>
              <Image
                width={220}
                height={220}
                src={protocol.src}
                alt={protocol.alt}
                className="mb-4 h-48 w-48 overflow-hidden rounded-xl object-cover sm:h-56 sm:w-56"
                sizes="224px"
                quality="80"
              />
              <h6
                className={classnames(
                  "mb-0.5 text-left font-semibold",
                  "text-sm md:text-base lg:text-lg",
                )}
              >
                {protocol.title}
              </h6>
              <div className="flex flex-row items-center justify-start gap-1">
                <p
                  className={classnames(
                    "text-left font-normal text-base-content/50",
                    "text-xs md:text-sm",
                  )}
                >
                  inspired by
                </p>
                <Image
                  src={protocol.inspoImg}
                  alt={protocol.inspoAlt}
                  width={20}
                  height={20}
                  className="h-4 w-4 rounded-full object-cover md:h-5 md:w-5"
                />
                <p
                  className={classnames(
                    "text-left font-normal text-base-content",
                    "text-xs md:text-sm",
                  )}
                >
                  {protocol.inspiration}
                </p>
              </div>
            </li>
          ))}
        </ul>
        <ul
          className="animate-infinite-scroll group-hover:pause flex items-start justify-start [&_img]:max-w-none [&_li]:mx-8"
          aria-hidden="true"
        >
          {protocols.map((protocol, index) => (
            <li key={index}>
              <Image
                width={220}
                height={220}
                src={protocol.src}
                alt={protocol.alt}
                className="mb-4 h-48 w-48 overflow-hidden rounded-xl object-cover sm:h-56 sm:w-56"
                sizes="224px"
                quality="80"
              />
              <h6
                className={classnames(
                  "mb-0.5 text-left font-semibold",
                  "text-sm md:text-base lg:text-lg",
                )}
              >
                {protocol.title}
              </h6>
              <div className="flex flex-row items-center justify-start gap-1">
                <p
                  className={classnames(
                    "text-left font-normal text-base-content/50",
                    "text-xs md:text-sm",
                  )}
                >
                  inspired by
                </p>
                <Image
                  src={protocol.inspoImg}
                  alt={protocol.inspoAlt}
                  width={20}
                  height={20}
                  className="h-4 w-4 rounded-full object-cover md:h-5 md:w-5"
                />
                <p
                  className={classnames(
                    "text-left font-normal text-base-content",
                    "text-xs md:text-sm",
                  )}
                >
                  {protocol.inspiration}
                </p>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </section>
  );
};

export default NoNonsense;
