import Image from "next/image";
import { faArrowUpRight } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classnames from "classnames";

const BeyondBaseline = () => {
  return (
    <section className="relative flex w-full flex-col items-center justify-center px-12 text-center">
      <h1
        className={classnames(
          "text-3xl font-extrabold text-base-content/80 md:text-4xl lg:text-5xl",
          "mb-2",
        )}
      >
        Go Beyond Baseline
      </h1>
      <p className="text-md pb-4 font-medium opacity-60 lg:text-xl ">
        {`Discover what's possible when you make`}{" "}
        <span className="whitespace-nowrap font-medium">
          health a priority.
        </span>
      </p>
      <div className="groupmax-w-screen-md group flex w-full flex-col items-center  ">
        <div className="relative flex w-full max-w-screen-md flex-row items-center justify-center">
          <Image
            src="/images/home/girl-boxing.svg"
            alt="beyond baseline"
            className={classnames(
              "h-auto w-full max-w-screen-md opacity-100 transition-all duration-500 ease-in-out group-hover:opacity-0",
              "absolute",
            )}
            width={1000}
            height={1000}
            sizes="(min-width: 1200px) 60vw, 100vw"
          />
          <Image
            src="/images/home/girl-boxing-focus.svg"
            alt="beyond baseline"
            className="h-auto w-full max-w-screen-md opacity-0 transition-all duration-500 ease-in-out group-hover:opacity-100"
            width={1000}
            height={1000}
            sizes="(min-width: 1200px) 60vw, 100vw"
          />
        </div>
        <a
          href="https://beta.vitalroutines.ai/sign-up"
          target="_blank"
          className={classnames(
            "flex h-[58px] w-[327px] flex-row items-center justify-center gap-0",
            "rounded-2xl bg-grapefruit-500 font-medium text-white shadow-lg",
            "transition-all duration-500 ease-in-out hover:bg-grapefruit-400 hover:shadow-none",
          )}
          rel="noreferrer"
        >
          Try it free
          <FontAwesomeIcon icon={faArrowUpRight} className="ml-2 h-3.5 w-3.5" />
        </a>
      </div>
    </section>
  );
};

export default BeyondBaseline;
