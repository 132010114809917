import { faBrightness, faMoon } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { useTheme } from "~/components/theme/ThemeContext";

const ThemeButton = () => {
  const { theme, toggleTheme } = useTheme();

  return (
    <button
      onClick={toggleTheme}
      className="flex aspect-square h-11 items-center justify-center rounded-md bg-base-content/5"
    >
      <FontAwesomeIcon
        icon={theme === "dark" ? faMoon : faBrightness}
        className="h-3.5 w-3.5 text-base-content"
      />
    </button>
  );
};

export default ThemeButton;
