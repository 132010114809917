import Image from "next/image";
import classnames from "classnames";

const WideCards = () => {
  return (
    <section
      className={classnames(
        "flex w-full max-w-screen-xl flex-col gap-8",
        "[&>div]:relative [&>div]:w-full [&>div]:overflow-hidden ",
        "px-12",
      )}
    >
      <Flow />
      <Track />
      <Privacy />
    </section>
  );
};

const Flow = () => (
  <div
    className={classnames(
      "group flex h-96 flex-col items-start justify-end rounded-2xl border border-base-200",
      "md:-8",
      "p-6 lg:p-10",
      "text-center sm:text-left",
    )}
  >
    <h2
      className={classnames(
        "w-full sm:w-fit",
        "z-10 text-base font-semibold md:text-lg lg:text-xl",
      )}
    >
      Find your flow
    </h2>
    <p
      className={classnames(
        "w-full sm:w-1/2 ",
        "z-10 text-sm font-light md:text-base lg:text-lg",
      )}
    >
      Adjust activities to fit your{" "}
      <span className="whitespace-nowrap font-light">busy schedule</span>
    </p>
    <div
      className={classnames(
        "bottom-0 right-0 top-10 z-0  rounded-tl-2xl shadow-lg",
        "relative sm:absolute",
        "transition-all duration-500 ease-in-out group-hover:scale-105",
        "h-full w-full sm:w-1/2",
      )}
    >
      <Image
        src="/images/home/weekly-cal.png"
        sizes="(min-width: 1000px) 35vw, 50vw"
        alt="vital routines weekly calendar view"
        fill
        className="block object-cover object-left-top dark:hidden"
        quality={100}
        draggable={false}
      />
      <Image
        src="/images/home/weekly-cal-dark.png"
        sizes="(min-width: 1000px) 35vw, 50vw"
        alt="vital routines weekly calendar view"
        fill
        className="hidden object-cover object-left-top dark:block"
        quality={100}
        draggable={false}
      />
    </div>
  </div>
);

const Track = () => (
  <div
    className={classnames(
      "group flex h-96 rounded-2xl border border-base-200 text-right",
      "flex-col-reverse sm:flex-row",
      "gap-8",
      "p-0 pl-4 md:pl-6 lg:pl-8",
      "items-center sm:items-end sm:justify-end",
    )}
  >
    <div
      className={classnames(
        "relative z-0 h-full w-full",
        "left-0 sm:left-8",
        "top-0 sm:top-12 md:top-28 lg:top-16 ",
        "max-w-[200px] sm:max-w-none",
        "group-hover:scale-105 group-hover:sm:top-8 group-hover:sm:scale-100 group-hover:md:top-20 group-hover:lg:top-12",
        "transition-all duration-500 ease-in-out",
      )}
    >
      <Image
        src="/images/home/day-cal.png"
        sizes="(min-width: 1000px) 35vw, 50vw"
        alt="vital routines weekly calendar view"
        fill
        className="block object-cover object-left-top dark:hidden"
        quality={100}
        draggable={false}
      />
      <Image
        src="/images/home/day-cal-dark.png"
        sizes="(min-width: 1000px) 35vw, 50vw"
        alt="vital routines weekly calendar view"
        fill
        className="hidden object-cover object-left-top dark:block"
        quality={100}
        draggable={false}
      />
    </div>
    <div
      className={classnames(
        "relative bottom-28 hidden h-full w-full md:block lg:bottom-16",
        "group-hover:bottom-20 group-hover:lg:bottom-12",
        "transition-all duration-500 ease-in-out",
      )}
    >
      <Image
        src="/images/home/day-cal.png"
        sizes="(min-width: 1000px) 35vw, 50vw"
        alt="vital routines weekly calendar view"
        fill
        className="block object-cover object-left-bottom dark:hidden"
        quality={100}
        draggable={false}
      />
      <Image
        src="/images/home/day-cal-dark.png"
        sizes="(min-width: 1000px) 35vw, 50vw"
        alt="vital routines weekly calendar view"
        fill
        className="hidden object-cover object-left-bottom dark:block"
        quality={100}
        draggable={false}
      />
    </div>
    <div
      className={classnames(
        "z-10 flex flex-shrink-0 flex-col justify-end ",
        "pt-10 sm:px-10 sm:pb-10",
        "text-center sm:text-right",
        "items-center sm:items-end",
        "w-full sm:w-1/2 md:w-1/3",
      )}
    >
      <h2 className="whitespace-nowrap text-base font-semibold md:text-lg   lg:text-xl">
        Track healthy habits
      </h2>
      <p className="whitespace-nowrap text-sm font-light md:text-base lg:text-lg">
        Achieve what you set out to do
      </p>
    </div>
  </div>
);

const Privacy = () => (
  <div className="md:-8 group flex h-96 flex-col items-start justify-center rounded-2xl bg-base-200 p-6 sm:justify-end lg:p-10">
    <h2 className=" whitespace-nowrap text-base font-semibold md:text-lg lg:text-xl">
      Keep data private
    </h2>
    <p className="max-w-[50vw] text-sm font-light md:text-base lg:text-lg">{`We don't sell your data or share it with other companies`}</p>
    <div
      className={classnames(
        "absolute max-w-[50vw]",
        "-right-12 sm:-bottom-16 sm:right-8 md:right-24",
        "h-80 w-80 lg:h-96 lg:w-96",
        "transition-opacity duration-500 ease-in-out group-hover:opacity-50",
      )}
    >
      <Image
        src="/images/home/shield-check.svg"
        alt="shield checkbox icon representing data privacy"
        className="block object-contain object-center sm:object-bottom dark:hidden"
        fill
        draggable={false}
      />
      <Image
        src="/images/home/shield-check-white.svg"
        alt="shield checkbox icon representing data privacy"
        className="hidden object-contain object-center sm:object-bottom dark:block"
        fill
        draggable={false}
      />
    </div>
  </div>
);

export default WideCards;
