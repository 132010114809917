import Image from "next/image";
import { faBookOpen } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classnames from "classnames";
import { motion } from "framer-motion";

const experts: Expert[] = [
  {
    name: "Peter Attia",
    title: "Longevity Expert",
    alt: "Peter Attia Portrait",
    src: "/images/experts/peter-attia.png",
    cols: "col-span-6 lg:col-span-4",
  },
  {
    name: "Matthew Walker",
    title: "Sleep Expert",
    alt: "Matthew Walker Portrait",
    src: "/images/experts/matthew-walker.png",
    cols: "col-span-6 lg:col-span-4",
  },
  {
    name: "Andrew Huberman",
    title: "Neuroscientist",
    alt: "Andrew Huberman Portrait",
    src: "/images/experts/andrew-huberman.png",
    cols: "col-span-6 md:col-span-4 lg:col-span-4",
  },
  {
    name: "Tim Ferris",
    title: "Author",
    alt: "Tim Ferris Portrait",
    src: "/images/experts/tim-ferris.png",
    cols: "col-span-6 md:col-span-4 lg:col-span-3",
  },
  {
    name: "Andy Galpin",
    title: "Human Bioenergetics",
    alt: "Andy Galpin Portrait",
    src: "/images/experts/andy-galpin.png",
    cols: "col-span-6 md:col-span-4 lg:col-span-3",
  },
  {
    name: "Wim Hof",
    title: `"The Ice Man"`,
    src: "/images/experts/wim-hof.png",
    alt: "Wim Hof Portrait",
    cols: "col-span-6 md:col-span-6 lg:col-span-3",
  },
  {
    name: "Ben Patrick",
    title: `"Knees Over Toes Guy"`,
    src: "/images/experts/ben-patrick.png",
    alt: "Ben Patrick Portrait",
    cols: "col-span-6 md:col-span-6 lg:col-span-3",
  },
  {
    name: "Rhonda Patrick",
    title: "Biomedical Scientist",
    src: "/images/experts/rhonda-patrick.png",
    alt: "Dr. Rhonda Patrick Portrait",
    cols: "col-span-6 md:col-span-4 lg:col-span-4",
  },
  {
    name: "Mike Israetel",
    title: "Sport Physiologist",
    alt: "Mike Israetel Portrait",
    src: "/images/experts/mike-israetel.png",
    cols: "col-span-6 md:col-span-4 lg:col-span-4",
  },
  // {
  //   name: "Bryan Johnson",
  //   title: "Biohacker",
  //   alt: "Bryan Johnson Portrait",
  //   src: "/images/experts/bryan-johnson.png",
  //   cols: "col-span-6 lg:col-span-3",
  // },
  {
    name: "Andy Puddicombe",
    title: "Former Buddhist Monk",
    alt: "Andy Puddicombe Portrait",
    src: "/images/experts/andy-puddicombe.png",
    cols: "col-span-6 md:col-span-4 lg:col-span-4",
  },
];

const InspiredBy = () => {
  return (
    <section className="flex w-full max-w-screen-xl flex-col items-center px-12">
      <motion.h2
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        transition={{ duration: 0.3, delay: 0.2 }}
        viewport={{ once: true }}
        className={classnames(
          "mb-2 w-full text-center font-bold text-base-content/80",
          "text-2xl md:text-3xl lg:text-4xl",
          "mb-2 md:mb-3 lg:mb-4",
        )}
      >
        Featuring Protocols{" "}
        <span className="whitespace-nowrap font-bold">Inspired By</span>
      </motion.h2>
      <motion.a
        href="/blog"
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        whileHover={{ opacity: 0.75, transition: { duration: 0.2, delay: 0 } }}
        transition={{ duration: 0.3, delay: 0.3 }}
        viewport={{ once: true }}
        className={classnames(
          "text-normal mb-8 w-fit max-w-screen-lg rounded-xl text-center text-base-content/60",
          "bg-base-300 text-xs md:text-sm lg:text-base",
          "border border-transparent px-4 py-1.5",
          "flex flex-row items-center gap-2.5",
        )}
      >
        <FontAwesomeIcon icon={faBookOpen} className="h-4 w-4" />
        How we design protocols
      </motion.a>
      <div className={classnames("w-full", "grid grid-cols-12", "gap-4")}>
        {experts.map(ExpertCard)}
      </div>
    </section>
  );
};

interface Expert {
  name: string;
  title: string;
  alt: string;
  src: string;
  cols: string;
}

const ExpertCard = ({ name, title, alt, src, cols }: Expert, i: number) => (
  <motion.div
    initial={{ opacity: 0 }}
    whileInView={{ opacity: 1 }}
    transition={{ duration: 0.5, delay: i * 0.1 + 0.5 }}
    key={name}
    viewport={{ once: true }}
    className={classnames(
      "col-span-4 rounded-xl",
      "flex items-center justify-start gap-2 border border-base-300",
      "flex-col md:flex-row",
      "text-center md:text-left",
      "p-2 md:p-3 lg:p-4",
      cols,
    )}
  >
    <Image
      src={src}
      alt={alt}
      width={100}
      height={100}
      draggable={false}
      className={classnames(
        "rounded-full bg-base-200 object-cover object-center",
        "h-12 w-12 md:h-16 md:w-16",
      )}
    />
    <div
      className={classnames("flex w-full flex-col items-start justify-start")}
    >
      <h6
        className={classnames(
          "w-full text-base font-medium",
          "text-sm md:text-base lg:text-lg",
        )}
      >
        {name}
      </h6>
      <p
        className={classnames(
          "w-full font-light",
          "text-xs md:text-sm lg:text-base",
        )}
      >
        {title}
      </p>
    </div>
  </motion.div>
);

export default InspiredBy;
