import type { NextPage } from "next";
import classnames from "classnames";

import BeyondBaseline from "~/components/home-page/beyond-baseline";
import Hero from "~/components/home-page/hero-section";
import InOneApp from "~/components/home-page/in-one-app";
import InnerLinks from "~/components/home-page/inner-links";
import InspiredBy from "~/components/home-page/inspired-by";
import NewWay from "~/components/home-page/new-way";
import NoNonsense from "~/components/home-page/no-nonsense";
import SimpleCards from "~/components/home-page/simple-cards";
import WideCards from "~/components/home-page/wide-cards";
import WorksForYou from "~/components/home-page/works-for-you";
import PageLayout from "~/components/layout/PageLayout";

const Home: NextPage = () => {
  return (
    <>
      <PageLayout
        seo={{
          title: "Home",
          description: "Preventative Health, Simplified.",
        }}
      >
        <div
          className={classnames(
            "flex flex-col items-center justify-center",
            "gap-32 sm:gap-40 md:gap-48 lg:gap-64",
            "py-28 sm:py-32 md:py-40",
          )}
        >
          <Hero />
          <InOneApp />
          <WorksForYou />
          <NoNonsense />
          <NewWay />
          <SimpleCards />
          <WideCards />
          <BeyondBaseline />
          <InspiredBy />
          <InnerLinks />
        </div>
      </PageLayout>
    </>
  );
};

export default Home;
