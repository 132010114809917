import classnames from "classnames";
import { motion } from "framer-motion";

const InOneApp = () => {
  return (
    <section className="w-full max-w-screen-2xl">
      <motion.h2
        initial={{ opacity: 0, y: 20 }}
        whileInView={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5, delay: 0.2 }}
        viewport={{ once: true }}
        className={classnames(
          "w-full text-left font-extrabold text-base-content/30",
          "text-4xl sm:text-5xl md:text-6xl lg:text-7xl",
          "px-12",
        )}
      >
        Estimate longevity. Discover holistic protocols. Personalize a routine.
        Track progress.{" "}
        <span className="whitespace-nowrap bg-gradient-to-r from-grapefruit-400 to-grapefruit-600 bg-clip-text font-extrabold text-transparent ">
          All with one app.
        </span>
      </motion.h2>
    </section>
  );
};

export default InOneApp;
