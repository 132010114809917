import { useState } from "react";
import Image from "next/image";
import Link from "next/link";
import classnames from "classnames";

const InnerLinks = () => {
  const [hovered, setHovered] = useState("blog");

  return (
    <section
      className={classnames(
        "w-full max-w-screen-xl gap-4 px-12 md:h-[300px] ",
        "flex flex-col md:flex-row",
        "[&>a]:min-h-[180px] [&>a]:rounded-2xl [&>a]:bg-base-200",
        "[&>a]:relative [&>a]:overflow-hidden",
        "[&>a>h2]:relative [&>a>h2]:z-20 [&>a>h2]:text-2xl [&>a>h2]:font-bold ",
        "group/list",
      )}
    >
      <Link
        href="/blog"
        className={classnames(
          hovered ? (hovered === "blog" ? "grow" : "grow-0") : "grow",
          "transition-all duration-500 md:w-1/4",
          hovered !== "blog" && "group-hover/list:opacity-50 ",
        )}
        onMouseEnter={() => setHovered("blog")}
      >
        <Title title="Blog" />
        <Image
          src="/images/blog/hike.jpg"
          alt="young man running on bridge in mountains"
          className="z-0 object-cover object-center"
          draggable={false}
          fill
          sizes="50vw"
        />
      </Link>
      <Link
        href="/about"
        className={classnames(
          hovered === "about" ? "grow" : "grow-0",
          "transition-all duration-500 md:w-1/4",
          hovered !== "about" && "group-hover/list:opacity-50 ",
        )}
        onMouseEnter={() => setHovered("about")}
      >
        <Title title="About" />
        <Image
          src="/images/vision-bg.jpg"
          alt="beatiful ocean beach and cliffside view"
          className="z-0 object-cover object-center"
          draggable={false}
          fill
          sizes="50vw"
        />
      </Link>
      <a
        href="https://beta.vitalroutines.ai/sign-up"
        target="_blank"
        className={classnames(
          hovered === "join" ? "grow" : "grow-0",
          "transition-all duration-500 md:w-1/4",
          hovered !== "join" && "group-hover/list:opacity-50 ",
        )}
        onMouseEnter={() => setHovered("join")}
        rel="noreferrer"
      >
        <Title title="Join Now" />
        <Image
          src="/images/plank.jpg"
          alt="illustration of young woman completing home workout for fitness plan"
          className="z-0 object-cover object-center"
          draggable={false}
          fill
          sizes="50vw"
        />
      </a>
    </section>
  );
};

const Title = ({ title }: { title: string }) => (
  <h2
    className={classnames(
      "w-fit rounded-br-2xl bg-base-100/90 px-6 py-3 text-base-content",
      title == "Join Now" && "bg-grapefruit-500 text-white",
    )}
  >
    {title}
  </h2>
);

export default InnerLinks;
