import { useEffect, useState } from "react";
import Image from "next/image";
import { faArrowUpRight } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classnames from "classnames";

const Hero = () => {
  const [loaded, isLoaded] = useState(false);
  const [scrollTop, setScrollTop] = useState(0);

  const handleScroll = () => {
    setScrollTop(window.scrollY);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    // clean up the event listener on component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <section
      className={classnames(
        "relative flex w-full max-w-screen-2xl flex-col items-center justify-center text-center",
        "px-12 md:px-24",
      )}
    >
      <div className="flex max-w-[700px] flex-col items-center">
        <h1
          className={classnames(
            "font-extrabold text-base-content/80",
            "text-3xl md:text-4xl lg:text-5xl",
            "mb-2 md:mb-3 lg:mb-4",
          )}
        >
          Vitality Simplified
        </h1>
        <p
          className={classnames(
            "mb-6 font-normal text-base-content/50",
            "text-sm sm:text-lg md:text-xl lg:text-2xl",
          )}
        >
          Unify your health journey in a single platform
        </p>
        <a
          href="https://beta.vitalroutines.ai/sign-up"
          target="_blank"
          className={classnames(
            "transition-all hover:scale-105 hover:bg-grapefruit-500/5 active:scale-100",
            "flex h-[58px] w-[327px] flex-row items-center justify-center gap-0",
            "rounded-2xl border border-grapefruit-500 font-medium text-grapefruit-500",
          )}
          rel="noreferrer"
        >
          Try it free
          <FontAwesomeIcon icon={faArrowUpRight} className="ml-2 h-3.5 w-3.5" />
        </a>
      </div>
      <Image
        src="/images/cal-preview-dark.png"
        alt="preventative health routine calendar and holistic protocol stack"
        width={1400}
        height={1400}
        sizes="80vh"
        quality={90}
        priority={true}
        className={classnames(
          "hidden rounded-2xl shadow-2xl shadow-white/5 dark:block",
          "mt-12 sm:mt-16 md:mt-20 lg:mt-28",
          "transition-all duration-300 ease-in-out",
          loaded ? "opacity-100" : "opacity-0",
          scrollTop > 50 && "scale-95",
        )}
        onLoad={() => isLoaded(true)}
        draggable={false}
      />
      <Image
        src="/images/cal-preview.png"
        alt="preventative health routine calendar and holistic protocol stack"
        width={1400}
        height={1400}
        sizes="80vh"
        quality={90}
        priority={true}
        className={classnames(
          "block rounded-2xl shadow-2xl dark:hidden",
          "mt-12 sm:mt-16 md:mt-20 lg:mt-28",
          "transition-all duration-300 ease-in-out",
          loaded ? "opacity-100" : "opacity-0",
          scrollTop > 50 && "scale-95",
        )}
        onLoad={() => isLoaded(true)}
        draggable={false}
      />
    </section>
  );
};

export default Hero;
