import {
  faCheckSquare,
  faLayerGroup,
  faRuler,
  faSearch,
  faSparkles,
  faWater,
} from "@fortawesome/pro-regular-svg-icons";
import type { IconDefinition } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classnames from "classnames";
import { motion } from "framer-motion";

interface Card {
  icon: IconDefinition;
  title: string;
  description: string;
}

const cards: Card[] = [
  {
    icon: faRuler,
    title: "Measure",
    description:
      "Measure key health indicators and get insights into your vitality",
  },
  {
    icon: faSearch,
    title: "Discover",
    description: "Find new holistic health protocols with benefits you love",
  },
  {
    icon: faLayerGroup,
    title: "Stack",
    description:
      "Design a unique protocol Stack to target your specific health goals",
  },
  {
    icon: faSparkles,
    title: "Personalize",
    description:
      "Customize plans to fit your needs and work around your schedule",
  },
  {
    icon: faCheckSquare,
    title: "Track",
    description: "Track your progress and see how you’re improving over time",
  },
  {
    icon: faWater,
    title: "Flow",
    description: "Adapt your Stack to your changing needs and goals",
  },
];

const SimpleCards = () => {
  return (
    <section
      className={classnames(
        "grid max-w-screen-xl",
        "grid-cols-2 gap-4 px-12 md:grid-cols-3 md:gap-6 lg:gap-8",
      )}
    >
      {cards.map(Card)}
    </section>
  );
};

const Card = ({ icon, title, description }: Card, i: number) => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      whileInView={{ opacity: 1 }}
      transition={{ duration: 0.3, delay: 0.1 * i }}
      key={title}
      viewport={{ once: true }}
      className={classnames(
        "flex flex-col items-start justify-start rounded-2xl",
        "gap-2 p-4 lg:gap-3 lg:p-6",
        "border border-base-300",
      )}
    >
      <div className="flex flex-row items-center justify-start gap-2 md:gap-2.5 lg:gap-3">
        <FontAwesomeIcon
          icon={icon}
          className={classnames(
            "h-3.5 w-3.5 text-grapefruit-500 md:h-4 md:w-4 lg:h-5 lg:w-5",
          )}
        />
        <h6
          className={classnames(
            "text-sm md:text-base lg:text-lg",
            "font-semibold",
          )}
        >
          {title}
        </h6>
      </div>
      <p
        className={classnames("text-xs md:text-sm lg:text-base", "font-light")}
      >
        {description}
      </p>
    </motion.div>
  );
};

export default SimpleCards;
