import {
  faArrowUpRight,
  faBandAid,
  faBolt,
  faFaceSpiralEyes,
  faGavel,
  faPills,
  faRepeat,
  faRoute,
  faShield,
  faSpa,
  faSparkles,
  faUser,
  faUserDoctor,
  faVirus,
} from "@fortawesome/pro-regular-svg-icons";
import type { IconDefinition } from "@fortawesome/pro-regular-svg-icons";
import { faArrowsCross } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classnames from "classnames";
import { AnimatePresence, motion } from "framer-motion";

const NewWay = () => {
  return (
    <section
      className={classnames(
        "flex flex-col items-center justify-center",
        "px-12 lg:px-24",
      )}
    >
      <AnimatePresence>
        <motion.h2
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          transition={{ duration: 0.3, delay: 0.2 }}
          viewport={{ once: true }}
          className={classnames(
            "mb-2 text-center font-bold text-base-content/80",
            "text-2xl md:text-3xl lg:text-4xl",
          )}
        >
          We do better as you do better
        </motion.h2>
      </AnimatePresence>
      <AnimatePresence>
        <motion.p
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          transition={{ duration: 0.3, delay: 0.3 }}
          viewport={{ once: true }}
          className={classnames(
            "text-normal mb-8 max-w-screen-lg text-center text-base-content/60",
            "text-sm md:text-base lg:text-lg",
          )}
        >
          Your vitality is our priority. No endless treatments, prescriptions,
          or intrusive procedures — just simple, effective, preventative
          protocols for a more energetic life.
        </motion.p>
      </AnimatePresence>
      <AnimatePresence>
        <motion.div
          initial={{ opacity: 0, y: 24 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.3, delay: 0.5 }}
          viewport={{ once: true }}
          className={classnames(
            "flex w-full flex-col items-center justify-center sm:flex-row sm:items-start",
            "gap-2 md:gap-4 lg:gap-6",
            "[&>div]:gap-6 [&>div]:rounded-xl [&>div]:p-4 [&>div]:md:p-8",
            "[&>div>h3]:mb-0 [&>div>h3]:font-semibold  [&>div>h3]:md:mb-2 ",
            "[&>div>h3]md:text-xl [&>div>h3]:text-lg [&>div>h3]:lg:text-2xl",
            "[&>div>p]:mb-6 [&>div>p]:text-xs [&>div>p]:font-normal [&>div>p]:text-base-content/60 [&>div>p]:md:text-sm [&>div>p]:lg:text-base",
            "[&>div>div]:flex [&>div>div]:flex-row [&>div>div]:gap-3 [&>div>div]:sm:flex-col",
            "[&>div>div>ul]:flex [&>div>div>ul]:flex-col [&>div>div>ul]:gap-3",
          )}
        >
          <div className="max-w-md flex-1 border-2 border-base-300">
            <h3>The Old Way</h3>
            <p>
              React to symptoms and rely on medication to treat them as they
              arise
            </p>
            <div>
              <ul>{oldWayBullets.slice(0, 4).map(renderBullet)}</ul>
              <ul>{oldWayBullets.slice(4).map(renderBullet)}</ul>
            </div>
          </div>
          <div className="relative max-w-md flex-1 border-2 border-grapefruit-500 bg-grapefruit-50 dark:bg-grapefruit-500/[0.18]">
            <h3>With Vital</h3>
            <p>
              Proactively monitor your health and push for continuous
              improvement
            </p>
            <div>
              <ul>{newWayBullets.slice(0, 4).map(renderBullet)}</ul>
              <ul>{newWayBullets.slice(4).map(renderBullet)}</ul>
            </div>
            <a
              href="https://beta.vitalroutines.ai"
              className={classnames(
                "absolute -bottom-10 right-0 flex flex-row items-center gap-1.5 font-medium",
                "opacity-50 transition-all duration-200 hover:underline hover:opacity-100",
              )}
            >
              Join now
              <FontAwesomeIcon
                icon={faArrowUpRight}
                className="-mb-0.5 h-3.5 w-3.5"
              />
            </a>
          </div>
        </motion.div>
      </AnimatePresence>
    </section>
  );
};

interface Bullet {
  label: string;
  icon: IconDefinition;
  color: string;
}

const oldWayBullets: Bullet[] = [
  {
    icon: faUserDoctor,
    label: "Annual Check-Up",
    color: "text-base-content/70",
  },
  { icon: faVirus, label: "Reactive Treatment", color: "text-base-content/70" },
  {
    icon: faPills,
    label: "Symptomatic Perscriptions",
    color: "text-base-content/70",
  },
  {
    icon: faArrowsCross,
    label: "Short-term Focus",
    color: "text-base-content/70",
  },
  {
    icon: faFaceSpiralEyes,
    label: "Conflicting Advice",
    color: "text-base-content/70",
  },
  {
    icon: faGavel,
    label: "Fear-Based Decision-Making",
    color: "text-base-content/70",
  },
  {
    icon: faBandAid,
    label: "Symptom Alleviation",
    color: "text-base-content/70",
  },
];

const newWayBullets: Bullet[] = [
  {
    icon: faRepeat,
    label: "Continuous Monitoring",
    color: "text-grapefruit-500",
  },
  {
    icon: faShield,
    label: "Preventative Routine",
    color: "text-grapefruit-500",
  },
  {
    icon: faSpa,
    label: "Holistic Protocol Alignment",
    color: "text-grapefruit-500",
  },
  { icon: faRoute, label: "Methodical Approach", color: "text-grapefruit-500" },
  {
    icon: faSparkles,
    label: "Personalized Recommendations",
    color: "text-grapefruit-500",
  },
  {
    icon: faUser,
    label: "Empowered Decision-Making",
    color: "text-grapefruit-500",
  },
  {
    icon: faBolt,
    label: "Vitality Optimization",
    color: "text-grapefruit-500",
  },
];

const renderBullet = ({ label, icon, color }: Bullet) => (
  <li
    key={label}
    className={classnames(
      "flex flex-row items-start gap-2 font-normal",
      "text-xs md:text-sm lg:text-base",
    )}
  >
    <div className="flex h-3.5 w-3.5 items-center md:h-5 md:w-4 lg:h-6">
      <FontAwesomeIcon
        icon={icon}
        className={classnames(color, "h-3.5 w-3.5 md:h-4 md:w-4")}
      />
    </div>
    {label}
  </li>
);

export default NewWay;
