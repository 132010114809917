import { useRef } from "react";
import Image from "next/image";
import { faArrowUpRight } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classnames from "classnames";

const WorksForYou = () => (
  <section
    className={classnames(
      "flex max-w-screen-2xl flex-col items-center px-12",
      "gap-8 md:gap-10 lg:gap-12 ",
    )}
  >
    <h2
      className={classnames(
        "text-center font-semibold text-base-content/80",
        "text-xl sm:text-2xl md:text-3xl lg:text-4xl",
      )}
    >
      You are unique, discover what{" "}
      <span className="whitespace-nowrap font-bold italic">works for you</span>
    </h2>
    <div
      className={classnames(
        "flex w-full flex-col gap-8 lg:grid lg:grid-cols-3",
        "[&>div]:rounded-2xl [&>div]:border [&>div]:border-base-300 [&>div]:p-4 [&>div]:lg:p-6 [&>div]:xl:p-10",
        "[&>div>p]:max-w-md [&>div>p]:text-xs [&>div>p]:font-normal [&>div>p]:text-base-content/60 [&>div>p]:md:text-sm [&>div>p]:lg:text-base",
        "[&>div>h3]:text-sm [&>div>h3]:font-semibold [&>div>h3]:md:text-base [&>div>h3]:lg:text-lg",
        "[&>div>div]:mb-4",
      )}
    >
      <Consolidate />
      <Stack />
      <Personalize />
      <Track />
      <a
        className={classnames(
          "col-span-full h-24 rounded-2xl text-base font-semibold md:text-lg",
          "bg-grapefruit-50 transition-all duration-200 hover:bg-grapefruit-500 hover:text-white dark:bg-grapefruit-500/[0.18] dark:hover:bg-grapefruit-500",
          "group flex flex-row items-center justify-center gap-2",
        )}
        href="https://beta.vitalroutines.ai"
      >
        Get started
        <FontAwesomeIcon
          icon={faArrowUpRight}
          className="h-3.5 w-3.5 duration-200 group-hover:mb-1 group-hover:ml-1 md:h-4 md:w-4"
        />
      </a>
    </div>
  </section>
);

const Consolidate = () => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const videoDarkRef = useRef<HTMLVideoElement>(null);

  const handleMouseEnter = () => {
    videoRef.current?.play().catch((e) => console.log(e));
    videoDarkRef.current?.play().catch((e) => console.log(e));
  };

  const handleMouseLeave = () => {
    videoRef.current && videoRef.current.pause();
    videoDarkRef.current && videoDarkRef.current.pause();
  };

  return (
    <div
      className="col-span-2 flex flex-col"
      onMouseEnter={() => handleMouseEnter()}
      onMouseLeave={() => handleMouseLeave()}
    >
      <div className="flex h-[350px] w-fit flex-col items-center justify-center self-center">
        <video
          ref={videoDarkRef}
          loop
          className={classnames(
            "hidden dark:block",
            "aspect-[658/400] object-cover object-center",
            "w-full md:h-full md:w-fit",
          )}
          disableRemotePlayback
          disablePictureInPicture
          crossOrigin="anonymous"
          playsInline
          muted
          preload="metadata"
          poster="https://res.cloudinary.com/dl7p14ddo/image/upload/v1696908084/vital/consolidate-dark-poster_raultd.jpg"
        >
          <source
            src="https://res.cloudinary.com/dl7p14ddo/video/upload/v1696907902/vital/consolidate-dark-ease_f96mro.mp4"
            type="video/mp4"
          />
        </video>
        <video
          ref={videoRef}
          loop
          muted
          className={classnames(
            "block dark:hidden",
            "aspect-[658/400] object-cover object-center lg:aspect-[658/400]",
            "w-full md:h-full md:w-fit",
          )}
          disableRemotePlayback
          disablePictureInPicture
          crossOrigin="anonymous"
          playsInline
          preload="metadata"
          poster="https://res.cloudinary.com/dl7p14ddo/image/upload/v1696908084/vital/consolidate-poster_blzohz.jpg"
        >
          <source
            src="https://res.cloudinary.com/dl7p14ddo/video/upload/v1696907902/vital/consolidate-ease_louuqk.mp4"
            type="video/mp4"
          />
        </video>
      </div>
      <div className="flex flex-row gap-2">
        <h3 className="text-sm font-semibold md:text-base lg:text-lg">
          Consolidate Your Data
        </h3>
        <div className="self-center rounded-full bg-base-200 px-2 py-1.5 text-xs leading-none">
          Coming Soon
        </div>
      </div>
      <p className="-mt-4">{`You can’t improve what you can’t measure. Consolidate and track all your health data in one place.`}</p>
    </div>
  );
};

interface Protocol {
  src: string;
  alt: string;
  title: string;
  inspiration: string;
  inspoImg: string;
  inspoAlt: string;
  benefits?: string[];
}

const protocols: Protocol[] = [
  {
    src: "/images/protocols/ice-bath.jpg",
    alt: "older woman enjoying ice bath in frozen lake",
    title: "Cold Exposure",
    inspiration: "Wim Hof",
    inspoAlt: "Wim Hof Portrait",
    inspoImg: "/images/experts/wim-hof.png",
    benefits: ["🎈", "🙂‍", "🧈", "🧍‍♀️"],
  },
  {
    src: "/images/protocols/morning-walks.jpg",
    alt: "young woman taking morning walk to get morning light from the sun and improve her health",
    title: "Morning Walks",
    inspiration: "Andrew Huberman",
    inspoAlt: "Andrew Huberman Portrait",
    inspoImg: "/images/experts/andrew-huberman.png",
    benefits: ["😄", "🛡️", "🧠", "🧘‍♂️"],
  },
  {
    src: "/images/protocols/vo2max.jpg",
    alt: "man at home riding stationary bike doing zone2 cardio to improve his vo2 max",
    title: "VO2 Max Training",
    inspiration: "Peter Attia",
    inspoAlt: "Peter Attia Portrait",
    inspoImg: "/images/experts/peter-attia.png",
    benefits: ["🫀", "🍬", "🛌", "🧍‍♂️"],
  },

  {
    src: "/images/protocols/resistance-training.jpg",
    alt: "young man doing resistance training with weights to improve his strength and muscle mass",
    title: "Resistance Training",
    inspiration: "Mike Israetel",
    inspoAlt: "Mike Israetel Portrait",
    inspoImg: "/images/experts/mike-israetel.png",
    benefits: ["💪", "🛌", "🧍‍♂️", "😄"],
  },
  {
    src: "/images/protocols/mindful-meditation.jpg",
    alt: "people seated on yoga mats with hands on knees in mindful meditation",
    title: "Mindful Meditation",
    inspiration: "Andy Puddicombe",
    inspoAlt: "Andy Puddicombe Portrait",
    inspoImg: "/images/experts/andy-puddicombe.png",
    benefits: ["🛡️", "😄", "🧠", "🧘‍♂️"],
  },
];

const Stack = () => (
  <div className="group col-span-1">
    <div
      className={classnames(
        "flex h-[350px] w-full flex-col-reverse justify-start",
      )}
    >
      {protocols.map(ProtocolPreview)}
    </div>
    <h3>Build Your Stack</h3>
    <p>
      Design a unique protocol Stack tailored to your specific{" "}
      <span className="whitespace-nowrap font-normal">health goals.</span>
    </p>
  </div>
);

const ProtocolPreview = (
  { src, alt, title, inspiration, inspoAlt, inspoImg, benefits }: Protocol,
  i: number,
) => {
  return (
    <div
      key={i}
      className={classnames(
        "mt-6 flex flex-row items-center justify-between py-2",
        "transition-all duration-500 group-hover:mt-1.5",
        i === 3 && "opacity-50 group-hover:opacity-100",
        i === 4 && "opacity-0  group-hover:opacity-100",
      )}
    >
      <div className="flex flex-row items-center gap-4">
        <Image
          className="h-12 w-12 rounded-lg object-cover object-center"
          width={48}
          height={48}
          src={src}
          alt={alt}
          draggable={false}
        />
        <div className="flex h-fit flex-col gap-0.5">
          <h6 className="text-sm font-medium md:text-base lg:text-sm ">
            {title}
          </h6>
          <div className="flex  flex-row items-center gap-1 text-xs">
            <p className="font-light text-base-content/50">inspired by </p>
            <Image
              className="h-4 w-4 rounded-full object-cover object-center"
              width={16}
              height={16}
              src={inspoImg}
              alt={inspoAlt}
              draggable={false}
            />
            <p className="hidden sm:block lg:hidden">{inspiration}</p>
          </div>
        </div>
      </div>
      <div className="-px-4 flex flex-row">{benefits?.map(Benefit)}</div>
    </div>
  );
};

const Benefit = (benefit: string) => (
  <p
    key={benefit}
    className="-mx-1.5 flex h-8 w-8 items-center justify-center rounded-full border border-base-300 bg-base-100 p-2"
  >
    {benefit}
  </p>
);

const Personalize = () => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const videoDarkRef = useRef<HTMLVideoElement>(null);

  const handleMouseEnter = () => {
    videoRef.current?.play().catch((e) => console.log(e));
    videoDarkRef.current?.play().catch((e) => console.log(e));
  };

  const handleMouseLeave = () => {
    videoRef.current && videoRef.current.pause();
    videoDarkRef.current && videoDarkRef.current.pause();
  };

  return (
    <div
      className="col-span-1 flex flex-col"
      onMouseEnter={() => handleMouseEnter()}
      onMouseLeave={() => handleMouseLeave()}
    >
      <div className="relative h-[350px] w-fit self-center bg-base-200">
        <video
          ref={videoDarkRef}
          loop
          muted
          className="hidden aspect-[932/1080] h-full object-cover object-center dark:block"
          disableRemotePlayback
          disablePictureInPicture
          crossOrigin="anonymous"
          playsInline
          preload="metadata"
          poster="https://res.cloudinary.com/dl7p14ddo/image/upload/v1696787435/vital/customize-vid-dark-poster_ufwdom.jpg"
        >
          <source
            src="https://res.cloudinary.com/dl7p14ddo/video/upload/v1696785947/vital/customize-vid-dark_d06xfl.mp4"
            type="video/mp4"
          />
        </video>
        <video
          ref={videoRef}
          loop
          muted
          className="block aspect-[233/270] h-full object-cover object-center dark:hidden"
          disableRemotePlayback
          disablePictureInPicture
          crossOrigin="anonymous"
          playsInline
          preload="metadata"
          poster="https://res.cloudinary.com/dl7p14ddo/image/upload/v1696787435/vital/customize-vid-poster_fuakud.jpg"
        >
          <source
            src="https://res.cloudinary.com/dl7p14ddo/video/upload/v1696785947/vital/customize-vid._w6cjgi.mp4"
            type="video/mp4"
          />
        </video>
      </div>
      <h3>Personalize Every Detail</h3>
      <p>Customize plans to fit your ability and work around your schedule.</p>
    </div>
  );
};

const Track = () => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const videoDarkRef = useRef<HTMLVideoElement>(null);

  const handleMouseEnter = () => {
    videoRef.current?.play().catch((e) => console.log(e));
    videoDarkRef.current?.play().catch((e) => console.log(e));
  };

  const handleMouseLeave = () => {
    videoRef.current && videoRef.current.pause();
    videoDarkRef.current && videoDarkRef.current.pause();
  };

  return (
    <div
      className="col-span-2 flex flex-col"
      onMouseEnter={() => handleMouseEnter()}
      onMouseLeave={() => handleMouseLeave()}
    >
      <div className="flex h-[350px] w-fit flex-col items-center justify-center self-center">
        <video
          ref={videoDarkRef}
          loop
          muted
          className={classnames(
            "hidden dark:block",
            "aspect-[17/10] object-cover object-center",
            "w-full md:h-full md:w-fit",
          )}
          disableRemotePlayback
          disablePictureInPicture
          crossOrigin="anonymous"
          playsInline
          preload="metadata"
          poster="https://res.cloudinary.com/dl7p14ddo/image/upload/v1697067957/vital/track-dark-poster_jfoz4t.jpg"
        >
          <source
            src="https://res.cloudinary.com/dl7p14ddo/video/upload/v1697067170/vital/track-dark_nvojfn.mp4"
            type="video/mp4"
          />
        </video>
        <video
          ref={videoRef}
          loop
          muted
          className={classnames(
            "block dark:hidden",
            "aspect-[17/10] object-cover object-center lg:aspect-[658/400]",
            "w-full md:h-full md:w-fit",
          )}
          disableRemotePlayback
          disablePictureInPicture
          crossOrigin="anonymous"
          playsInline
          preload="metadata"
          poster="https://res.cloudinary.com/dl7p14ddo/image/upload/v1697067957/vital/track-light-poster_gpr3tv.jpg"
        >
          <source
            src="https://res.cloudinary.com/dl7p14ddo/video/upload/v1697067545/vital/track-light_rfdlvi.mp4"
            type="video/mp4"
          />
        </video>
      </div>
      <div className="flex flex-row gap-2">
        <h3 className="text-sm font-semibold md:text-base lg:text-lg">
          Track & Gauge Efficacy
        </h3>
      </div>
      <p className="-mt-4">
        {`Compare protocol adherence with changes in your health to discover what's
        worth your time and effort.`}
      </p>
    </div>
  );
};

export default WorksForYou;
